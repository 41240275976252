import React from 'react';

interface Props {
    className?: string,
    centre?: boolean,
    paddingBottom?: number,
}

const Body: React.FunctionComponent<Props> = props => {
    const paddingBottom = props.paddingBottom !== undefined ? props.paddingBottom : 4;
    const centre = props.centre !== undefined ? props.centre : false;

    let classes = `modal-body pb-${paddingBottom} ${props.className}`;
    if (centre) classes += ' text-center'

    return (
        <div className={classes}>
            { props.children }
        </div>
    );
}

export default Body;
