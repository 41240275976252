import React from 'react';

const Row: React.FunctionComponent = props => {
    return (
        <div className="media">
            {props.children}
        </div>
    );
}

export default Row;
