import * as interfaces from "../Interfaces";
import {MobileMenuType, Modal} from "../Enums";
import {normalize, schema} from 'normalizr';

const screenSchema = new schema.Entity('screens');
screenSchema.define({children: new schema.Array(screenSchema)});

const screenDataRaw = {
    id: "",
    title: "",
    children: [],
};

const normalizedScreens = normalize<interfaces.ScreenData>(screenDataRaw, screenSchema);
const screenData = normalizedScreens.entities.screens!;

export interface IAppState {
    readonly portalUrl: string,
    readonly appBaseUrl: string,
    readonly currentModal: Modal | null,
    readonly screenIds: string[],
    readonly screenData: { [key: string]: interfaces.ScreenData; },
    readonly mobileMenuType: MobileMenuType,
    readonly history: Modal[],
    readonly isEmbedded: boolean,
    readonly productQuoteData: interfaces.ProductQuoteData,
    readonly productSectorData: interfaces.ProductSectorData,
}

const initialState: IAppState = {
    portalUrl: '/',
    appBaseUrl: "/",
    currentModal: null,
    screenIds: [],
    screenData: screenData,
    mobileMenuType: MobileMenuType.main,
    history: [],
    isEmbedded: false,
    productQuoteData: { data: [], selectedProduct: null, sector: null },
    productSectorData: { data: [], sector: null, productId: null, periodical: null },
};

const reducers = (state=initialState, action: any): IAppState => {
    switch (action.type) {
        case "SET_NEXT_MODAL": {
            const historyNext = [...state.history];
            if (historyNext[historyNext.length - 1] !== action.payload) {
                historyNext.push(action.payload);
            }

            return {
                ...state,
                currentModal: action.payload,
                history: historyNext
            };
        }
        case "SET_PREVIOUS_MODAL": {
            const historyPrevious = [...state.history];
            historyPrevious.pop();

            return {
                ...state,
                currentModal: historyPrevious[historyPrevious.length - 1],
                history: historyPrevious
            };
        }
        case "CLOSE_MODAL":
            return {
                ...state,
                currentModal: null,
                history: []
            };
        case "ADD_SCREEN":
            return {...state, screenIds: [...state.screenIds, action.payload]};
        case "REMOVE_SCREEN": {
            const screens = Array.from(state.screenIds);
            if (screens.length) screens.pop();
            return {...state, screenIds: screens};
        }
        case "CLEAR_SCREEN":
            return {...state, screenIds: []};
        case "CHANGE_SCREEN_DATA": {
            const screenDataRaw = JSON.parse(action.payload);
            const normalizedScreens = normalize<interfaces.ScreenData>(screenDataRaw, screenSchema);

            return {...state, screenData: normalizedScreens.entities.screens!};
        }
        case "CHANGE_PORTAL_URL":
            return {...state, portalUrl: action.payload};
        case "CHANGE_APP_BASE_URL":
            return {...state, appBaseUrl: action.payload};
        case "SET_MOBILE_MENU_TYPE":
            return {...state, mobileMenuType: action.payload};
        case "SET_IS_EMBEDDED":
            return {...state, isEmbedded: action.payload};
        case "CHANGE_PRODUCT_QUOTE_DATA":
            return { ...state, productQuoteData: action.payload };
        case "CHANGE_PRODUCT_SECTOR_DATA":
            return { ...state, productSectorData: action.payload };
        default:
            return state;
    }
};

export default reducers;
