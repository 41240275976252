import {IAppState} from "./Reducers";
import {MobileMenuType, Modal} from "../Enums";
import {ThunkAction} from "redux-thunk";
import { ProductQuoteData, ProductSectorData } from "../Interfaces";

export const nextModal = (modal: Modal): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "SET_NEXT_MODAL", payload: modal});
    };
};

export const previousModal = (): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "SET_PREVIOUS_MODAL"});
    };
};

export const closeModal = (): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "CLOSE_MODAL"});
    };
};

export const addScreen = (
    screenId: string,
): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch, getState)  => {
        // if the screen for the given id contains a dummy screen (used for vertically aligning menu items in the
        // desktop menu), skip the dummy and just show the child instead
        const screenData = getState().screenData;
        const screen = screenData[screenId];
        const children = screenData[screenId].children;

        if (children.length === 1) {
            const child = screenData[children[0]];

            if (child.title === screen.title) {
                screenId = child.id;
            }
        }

        dispatch({type: "ADD_SCREEN", payload: screenId});
    };
};

export const removeScreen = (): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "REMOVE_SCREEN"});
    };
};

export const clearScreen = (): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "CLEAR_SCREEN"});
    };
};

export const changeScreenData = (screenDataJson: string): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "CLEAR_SCREEN"});
        if (screenDataJson) {
            dispatch({type: "CHANGE_SCREEN_DATA", payload: screenDataJson});
        }
    };
};

export const initialize = (
    screenDataJson: string,
    portalUrl: string,
    appBaseUrl: string,
    embedScreen: Modal | null,
): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch(changeScreenData(screenDataJson));
        dispatch({type: "CHANGE_PORTAL_URL", payload: portalUrl});
        dispatch({type: "CHANGE_APP_BASE_URL", payload: appBaseUrl});

        if (embedScreen) {
            dispatch({type: "SET_NEXT_MODAL", payload: embedScreen});
            dispatch({type: "SET_IS_EMBEDDED", payload: true});
        }
    };
};

export const setMobileMenuType = (type: MobileMenuType): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({type: "SET_MOBILE_MENU_TYPE", payload: type});
    };
};

export const changeProductQuoteData = (productQuoteData: ProductQuoteData): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({ type: "CHANGE_PRODUCT_QUOTE_DATA", payload: productQuoteData });
    };
};

export const changeProductSectorData = (productSectorData: ProductSectorData): ThunkAction<void, IAppState, undefined, any> => {
    return (dispatch) => {
        dispatch({ type: "CHANGE_PRODUCT_SECTOR_DATA", payload: productSectorData });
    };
};
