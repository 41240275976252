import React from 'react';

const Column: React.FunctionComponent = props => {
    return (
        <div className="media-body">
            {props.children}
        </div>
    );
}

export default Column;
