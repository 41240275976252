import * as actions from "./store/Actions";
import {GetScreen} from "./Utilities";
import {IAppState} from "./store/Reducers";
import {MobileMenuType, Modal} from "./Enums";
import {OpenModalData} from "./Interfaces";
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

function Modals() {
    const isEmbedded = useSelector((state: IAppState) => state.isEmbedded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmbedded) return;

        const openModal = (event: CustomEvent) => {
            const data = JSON.parse(event.detail) as OpenModalData;

            if (data.modal === Modal.mobileMenu) {
                dispatch(actions.setMobileMenuType(data.type as MobileMenuType));
            }

            dispatch(actions.clearScreen());
            dispatch(actions.nextModal(data.modal));
        };

        document.addEventListener("open-modal", openModal);
        (window as any).reactReady = true;

        return () => {
            if (isEmbedded) return;
            document.removeEventListener("open-modal", openModal);
            (window as any).reactReady = false;
        };
    }, [isEmbedded]);

    const currentModal = useSelector((state: IAppState) => state.currentModal);
    const modal = GetScreen(currentModal);

    return (
        <>
            {
                modal && (
                    <div className="modal-open" style={{display: "block"}}>
                        { modal }
                        {
                            !isEmbedded && <div className="modal-backdrop fade show" />
                        }
                    </div>
                )
            }
        </>
    );
}

export default Modals;
