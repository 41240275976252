import React from 'react';

interface Props {
    justify?: "center",
}

const FormRow: React.FunctionComponent<Props> = props => {
    let classes = 'form-group form-row mb-0';
    if (props.justify === "center") classes += ' justify-content-center';

    return (
        <div className={classes}>
            {props.children}
        </div>
    );
};

export default FormRow;
