export enum Modal {
  contactUs = "contactUs",
  getStarted = "getStarted",
  watchVideo = "watchVideo",
  enquiry1 = "enquiry1",
  noviceEnquiry = "noviceEnquiry",
  contactEnquiry = "contactEnquiry",
  hqEnquiry = "hqEnquiry",
  partnerEnquiry = "partnerEnquiry",
  premierPlus = "premierPlus",
  cashPlus = "cashPlus",
  referrals = "referrals",
  subscribe = "subscribe",
  mobileMenu = "mobileMenu",
  productQuote = "productQuote",
  productQuoteScreen1 = "Screen1",
  productQuoteScreen2 = "Screen2",
  productQuoteScreen3 = "Screen3",
  productSector = "productSector",
  productSectorScreen1 = "SectorScreen1",
  productSectorScreen2 = "SectorScreen2",
}

export enum MobileMenuType {
    main = "main",
    user = "user",
}

export enum ModalSize {
  normal = "",
  large = "modal-lg",
  extraLarge = "modal-xl",
}
