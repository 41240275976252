import React from 'react';
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import * as components from "../../components";
import { ModalSize } from "../../../Enums";
import './ProductQuote.scss';
import { IAppState } from '../../../store/Reducers';

export const Screen3 = (): JSX.Element => {
  const productQuoteData = useSelector((state: IAppState) => state.productQuoteData);
  const lowerTooltip = productQuoteData.selectedProduct['lower_tooltip'];
  const midTooltip = productQuoteData.selectedProduct['mid_tooltip'];
  const upperTooltip = productQuoteData.selectedProduct['upper_tooltip'];

  return (
    <components.Container className="quote-modal-container" size={ModalSize.normal}>
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="pb-5">
        <components.Row>
          <components.Column>
            <components.Title>Your Indicative LawBite Quote</components.Title>
            <p>Our typical range for {productQuoteData.selectedProduct['title']} work within the {productQuoteData.sector} sector is as follows:</p>
          </components.Column>
        </components.Row>

        <hr className="my-1" />

        <div className="mt-3">
          <div className='row quote-limit-container'>
            <div className='col-md-4 text-center lower-limit'>
              <h2 className="quote-limit-text pb-2">{productQuoteData.selectedProduct['lower_limit']}</h2>
              <div className='d-flex justify-content-center'>
                <span className='quote-dot lower-limit-dot'></span>
              </div>
              <span
                className='quote-dot-text'
                {...lowerTooltip && { 'data-tip': lowerTooltip }}
              >REVIEW</span>
            </div>
            <div className='col-md-4 text-center mid-limit'>
              <h2 className="quote-limit-text pb-2">{productQuoteData.selectedProduct['mid_limit']}</h2>
              <div className='d-flex justify-content-center'>
                <span className='quote-dot mid-limit-dot'></span>
              </div>
              <span
                className='quote-dot-text'
                {...midTooltip && { 'data-tip': midTooltip }}
              >AVERAGE</span>
            </div>
            <div className='col-md-4 text-center upper-limit'>
              <h2 className="quote-limit-text pb-2">{productQuoteData.selectedProduct['upper_limit']}</h2>
              <div className='d-flex justify-content-center'>
                <span className='quote-dot upper-limit-dot'></span>
              </div>
              <span
                className='quote-dot-text'
                {...upperTooltip && { 'data-tip': upperTooltip }}
              >DRAFT</span>
            </div>
            <ReactTooltip className="tooltip" type="dark" place="bottom" />
          </div>

          <div className='row border-bottom'>
            <div className='col-md-12 text-center pb-5 footer-as-bg'>
              <div className="vertical_dotted_line"></div>
              <h2>{productQuoteData.selectedProduct['percentage']}%</h2>
              <p>of quotes fall within the above price bracket (EX VAT)</p>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-12 text-center'>
              <a className='btn btn-pill-primary' data-method="put" href={'/quote/redirect?id=' + productQuoteData.selectedProduct['id']}>
                Talk to one of our lawyers for free
              </a>
            </div>
          </div>
        </div>

      </components.Body>
    </components.Container>
  );
};

export default Screen3;