import React from 'react';
import * as components from "../../../components";
import { onFormReady } from "../Helpers";

export const Subscribe = (): JSX.Element => {
  const target = 'hubspotSubscribeForm';

  if (window["hbspt"]) {
    window["hbspt"].forms.create({
      region: 'eu1',
      portalId: '8639698',
      formId: 'acbe874e-4159-4353-b560-a9b69aeda98b',
      formInstanceId: 'form_acbe874e-4159-4353-b560-a9b69aeda98b',
      target: '#' + target,
      submitButtonClass: 'btn btn-primary',
      errorClass: 'error',
      locale: 'en',
      translations: { en: { required: "required" } },
      onFormReady: function ($form) {
        onFormReady($form, target);
      },
    })
  }

  return (
    <components.Container className="subscribe-form no-background-modal">
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body>
        <components.Loader></components.Loader>

        <div>
          <div id="hubspotSubscribeForm"></div>
        </div>
      </components.Body>
    </components.Container>
  );
}

export default Subscribe;
