import React from 'react';
import {ModalSize} from "../../Enums";
import {IAppState} from "../../store/Reducers";
import {useSelector} from "react-redux";
import './Container.scss';

interface Props {
    className?: string,
    size?: ModalSize,
}

const Container: React.FunctionComponent<Props> = props => {
    const isEmbedded = useSelector((state: IAppState) => state.isEmbedded);

    let classesModal = "modal fade show";
    if (isEmbedded) classesModal += " embedded";
    if (props.className) classesModal += ` ${props.className}`;

    let classesDialog = "modal-dialog";
    if (!isEmbedded) classesDialog += " modal-dialog-centered ";
    if (props.size) classesDialog += ` ${props.size}`;

    return (
        <div className={classesModal} id="modal" tabIndex={-1} role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className={classesDialog}>
                <div className="modal-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Container;
