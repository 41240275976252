import React from 'react';
import {ModalSize} from '../Enums';
import * as components from './components';

function WatchVideo() {
  const activeModal = document.querySelector(".watch-video") as HTMLElement;
  const modalVideoName = activeModal.dataset.video;

  const videoUrlSource = {
    'homepage': 'https://player.vimeo.com/video/533465714?autoplay=1&portrait=0',
    'connect': 'https://player.vimeo.com/video/533465872?autoplay=1&portrait=0',
    'international-hub': 'https://player.vimeo.com/video/545861426?autoplay=1&portrait=0',
    'hq': 'https://player.vimeo.com/video/561230379?autoplay=1&portrait=0'
  };

  return (
    <components.Container className='watch-video-modal' size={ModalSize.large}>
      <components.Header>
        <components.Close />
      </components.Header>
      <components.Body className='pb-5 text-center'>
        <div className='embed-responsive embed-responsive-16by9'>
          <iframe 
            src={videoUrlSource[modalVideoName]} 
            allowFullScreen
          >
          </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </components.Body>
    </components.Container>
  );
}

export default WatchVideo;
