import React from 'react';

interface Props {
  class?: string
}

const Loader: React.FunctionComponent<Props> = props => {
  const classes = "spinner-border " + props.class;
  return (
    <div className="text-center">
      <div className={classes} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
