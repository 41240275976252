import React from 'react';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/Actions";
import * as components from "../../components";
import { Modal, ModalSize } from "../../../Enums";
import { ProductQuoteFormData } from "../../../Interfaces";
import './ProductQuote.scss';
import { IAppState } from '../../../store/Reducers';
import { useForm } from 'react-hook-form';
import { sectorOptions } from './sectors';

export const Screen1 = (): JSX.Element => {
  const dispatch = useDispatch();
  const productQuoteData = useSelector((state: IAppState) => state.productQuoteData);
  const form = useForm<ProductQuoteFormData>();
  const sectors = sectorOptions.OPTIONS;

  const loadData = async () => {
    axios.defaults.headers = {
      Accept: 'application/json',
      withCredentials: false,
      'Content-Type': 'application/json',
    };
    await axios.get('/api/product_quote_ranges')
      .then(response => {
        const data = { data: response.data, selectedProduct: null, sector: null };
        dispatch(actions.changeProductQuoteData(data));
      })
      .catch(error => console.log(error))
  };
  if (productQuoteData.data.length === 0) {
    loadData();
  }

  const onSubmit = (formData: ProductQuoteFormData) => {
    const data = {
      ...productQuoteData,
      sector: formData.sector,
    };
    dispatch(actions.changeProductQuoteData(data));
    dispatch(actions.nextModal(Modal.productQuoteScreen2));
  };

  return (
    <components.Container className="quote-modal-container" size={ModalSize.normal}>
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="no-bottom-padding footer-as-bg bottom-radius">
        <components.Row>
          <components.Column>
            <components.Title>Get an Indicative Quote</components.Title>
          </components.Column>
        </components.Row>

        <hr className="my-1" />

        <p className="mt-3">
          Why not <strong>get an indicative quote for a piece of legal work.</strong> All it takes is a few questions and less than a minute to see how affordable LawBite could be for your business.
        </p>
        <p>
          First of all please tell us which sector you work in...
        </p>

        <form className="mt-4 middle-screen-with-bg" onSubmit={form.handleSubmit(onSubmit)}>
          <components.FormRow>
            <components.FormColumn widthMedium={11}>
              <components.ControlSelect
                name="sector"
                placeholder="Select a sector..."
                options={sectors}
                form={form}
                rules={{ required: 'Please select an option' }}
              />
            </components.FormColumn>
          </components.FormRow>

          <button className='btn btn-pill-primary'>
            Continue
          </button>
        </form>
      </components.Body>
    </components.Container>
  );
};

export default Screen1;
