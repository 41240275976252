import Choices from "choices.js";
import jQuery from 'jquery';
import gtag from "../../../../../../config/analytics";

(window as any).jQuery = jQuery;
(window as any).$ = jQuery;

export const createForm = (
  portalId: string, 
  formId: string, 
  target: string, 
  headerClass: string, 
  formName?: string) => {
  if (window["hbspt"]) {
    window["hbspt"].forms.create({
      region: "eu1",
      portalId: portalId,
      formId: formId,
      formInstanceId: 'form_' + formId,
      target: '#' + target,
      css: '',
      submitButtonClass: 'btn btn-primary btn-lg btn-block',
      errorClass: 'error',
      locale: 'en',
      translations: { en: { required: "required" } },
      onFormReady: function ($form) {
        onFormReady($form, target);
      },
      onFormSubmit: function ($form) {
        onSubmit($form, target);
      },
      onFormSubmitted: function ($form) {
        gtag('event', formName, {
          'send_to': document.querySelector(`meta[name="ga_measurement_id"]`).getAttribute('content'),
          'transaction_id': document.querySelector(`meta[name="page_visit_id"]`).getAttribute('content')
        });
        onSubmitted($form, target, headerClass);
      },
    })
  }
};

export const onFormReady = ($form: any, target: string) => {
  const form = document.getElementById(target);
  const spinners = document.querySelectorAll('.spinner-border');
  const select = form.querySelector('select');
  const checkBoxes = form.querySelectorAll('input[type=checkbox]');
  const terms = $('#' + target).find('input[name="terms_of_use_and_privacy_policy_acceptance"]');
  terms.prop('checked', false).change(function () {
    $("#" + target).find('.hs-error-msg').hide();
  });

  const setupSelect = () => {
    if (!select) { return false }

    new Choices(select, {
      removeItemButton: true,
      shouldSort: false
    });
  };

  const setupCheckBoxes = () => {
    if (!checkBoxes) { return false }

    checkBoxes.forEach((box) => {
      box.addEventListener('change', function (e) {
        const target = e.target as HTMLInputElement
        if (target.checked) {
          target.parentElement.classList.add('checked-label');
        } else {
          target.parentElement.classList.remove('checked-label');
        }
      });
    });
  }

  const showForm = () => {
    spinners.forEach((spinner) => {
      spinner.setAttribute('hidden', 'true');
    });
    form.style.display = 'block';
  }

  const main = () => {
    setupSelect();
    setupCheckBoxes();
    showForm();
  }
  main();
};

export const onSubmit = ($form: any, target: string) => {};

export const onSubmitted = ($form: any, target: string, headerClass: string) => {
  const header = document.querySelector("h3."+headerClass);
  const headerText = document.querySelector("p."+headerClass);

  header.innerHTML = 'Thanks for your enquiry';
  headerText.innerHTML = '';
};
