import * as actions from "./store/Actions";
import React, {useEffect} from 'react';
import Modals from "./Modals";
import {useDispatch} from "react-redux";
import {Modal} from "./Enums";
import './App.scss';

interface IAppProps {
    screenData: string,
    portalUrl: string,
    appBaseUrl: string,
    embedScreen: Modal,
}

const App = (props: IAppProps): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.initialize(
            props.screenData,
            props.portalUrl,
            props.appBaseUrl,
            props.embedScreen,
        ));
    }, []);

    return <Modals />;
};

export default App;
