import React from 'react';

interface Props {
    width?: number,
    widthMedium?: number,
}

const FormColumn: React.FunctionComponent<Props> = props => {
    const width = props.width || 12;
    let classes = `col-${width} mb-3`;
    if (props.widthMedium) classes += ` col-md-${props.widthMedium}`;

    return (
        <div className={classes}>
            {props.children}
        </div>
    );
};

export default FormColumn;
