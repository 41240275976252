import * as actions from "../../store/Actions";
import React from 'react';
import {useDispatch} from "react-redux";

interface Props {
    customAction?: () => void,
}

const Close: React.FunctionComponent<Props> = props => {
    const dispatch = useDispatch();

    const closeClicked = () => {
        dispatch(actions.closeModal());
    };

    const action = props.customAction || closeClicked;

    return (
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={action}>
            <span aria-hidden="true">&times;</span>
        </button>
    );
};

export default Close;
