export class sectorOptions {
  static readonly OPTIONS = [
    ["", "Select a sector..."],
    ["Accounting", "Accounting"],
    ["Banking", "Banking"],
    ["Business Services", "Business Services"],
    ["Charities", "Charities"],
    ["Construction", "Construction"],
    ["Consultancy", "Consultancy"],
    ["E-commerce", "E-commerce"],
    ["Education", "Education"],
    ["Energy and Utilities", "Energy and Utilities"],
    ["Environmental", "Environmental"],
    ["Events", "Events"],
    ["Fashion", "Fashion"],
    ["Financial", "Financial"],
    ["Food and Beverage", "Food and Beverage"],
    ["Health and Safety", "Health and Safety"],
    ["Hair & Beauty", "Hair & Beauty"],
    ["Hospitality", "Hospitality"],
    ["HR", "HR"],
    ["Insurance", "Insurance"],
    ["Legal", "Legal"],
    ["Logistics and Infrastructure", "Logistics and Infrastructure"],
    ["Manufacturing", "Manufacturing"],
    ["Marketing and Advertising", "Marketing and Advertising"],
    ["Media/Entertainment", "Media/Entertainment"],
    ["Medical", "Medical"],
    ["Music", "Music"],
    ["Oil and Gas", "Oil and Gas"],
    ["Other", "Other"],
    ["Pharmaceuticals", "Pharmaceuticals"],
    ["Property/Real Estate", "Property/Real Estate"],
    ["Public Services", "Public Services"],
    ["Public Relations", "Public Relations"],
    ["Retail", "Retail"],
    ["Sport", "Sport"],
    ["Social Care", "Social Care"],
    ["Technology", "Technology"],
    ["Telecoms", "Telecoms"],
    ["Trades", "Trades"],
    ["Training", "Training"],
    ["Transport", "Transport"],
    ["Tourism and Leisure", "Tourism and Leisure"],
  ] as [string, string][];
}

export default sectorOptions;