import React from "react";
import {useSelector} from "react-redux";
import * as components from "./components";
import { IAppState } from "../store/Reducers";
import { refCookieParams } from "../../../../src/cookie";

function GetStarted() {
  const portalUrl = useSelector((state: IAppState) => state.portalUrl);
  const portalParams = refCookieParams();

  const expertClicked = (event: React.MouseEvent) => {
    window.location.href = portalUrl + "/onboarding/enquiries" + portalParams;
    event.preventDefault();
  }

  const essentialsClicked = (event: React.MouseEvent) => {
    window.location.href = portalUrl + "/onboarding/subscriptions" + portalParams;
    event.preventDefault();
  }

  return (
    <components.Container className="get-started-modal">
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="pb-5 text-center">
        <h2> 
            What would you like to do now?
        </h2>

        <div className="mt-5 mx-3">
            <a href="#" className="btn btn-primary btn-lg btn-block" onClick={expertClicked}>
              Talk to a Lawyer
            </a>

            <a href="#" className="btn btn-primary btn-lg btn-block mt-3" onClick={essentialsClicked}>
              Essential legal support - sign-up for free
            </a>
        </div>
      </components.Body>
    </components.Container>
  )
}

export default GetStarted;
