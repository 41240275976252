import * as actions from "../store/Actions";
import closeIcon from 'images/mobile-menu/close.svg'
import {CSSTransition} from "react-transition-group";
import {IAppState} from "../store/Reducers";
import MobileMenuContent from "./MobileMenuContent";
import React from 'react';
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {useState} from 'react';
import './MobileMenu.scss';

function MobileMenu() {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(true);

    const screensIds = useSelector((state: IAppState) => state.screenIds);

    const onExited = () => {
        dispatch(actions.closeModal());
    }

    return (
        <CSSTransition in={showModal} timeout={700} appear={true} onExited={onExited} classNames="mobile-menu">
            <div className="modal fade mobile-menu">
                <div className="screens">
                    <MobileMenuContent primary={true} screenId="" isRoot={true} />
                    {
                        screensIds.map(screenId => <MobileMenuContent primary={false} screenId={screenId} />)
                    }
                </div>
                <div className="side">
                    <div className="close-menu" onClick={() => setShowModal(false)}>
                        <img src={closeIcon} alt="Close Menu" />
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
}

export default MobileMenu;
