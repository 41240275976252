import React from 'react';

const Header: React.FunctionComponent = props => {
    return (
        <div className="modal-header border-0">
            { props.children }
        </div>
    );
};

export default Header;
