import React from "react";
import { useForm } from "react-hook-form";
import { ModalSize } from "../../../Enums";
import * as components from "../../components";
import { ProductSectorFormData } from "../../../Interfaces";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Reducers";
import { refCookieParams } from "../../../../../../src/cookie";

export const SectorScreen2 = (): JSX.Element => {
  const form = useForm<ProductSectorFormData>();
  const productSectorData = useSelector((state: IAppState) => state.productSectorData);
  const portalUrl = useSelector((state: IAppState) => state.portalUrl);
  const selectData = productSectorData.data[productSectorData.periodical];

  const onSubmit = (formData: ProductSectorFormData) => {
    window.location.href = portalUrl + "/onboarding/subscriptions?plan_id=" + formData.sector + refCookieParams("&");
  };

  return (
    <components.Container className="sector-modal-container" size={ModalSize.normal}>
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="bottom-radius">
        <components.Row>
          <components.Column>
            <components.Title>Select your sector</components.Title>
          </components.Column>
        </components.Row>

        <hr className="my-1" />

        <p className="mt-3">
          So we can tailor your Foundations Plus plan to suit your needs please confirm which industry sector you work in.
        </p>

        <form className="mt-4 middle-screen-with-bg" onSubmit={form.handleSubmit(onSubmit)}>
          <components.FormRow>
            <components.FormColumn widthMedium={11}>
              <components.ControlSelect
                name="sector"
                placeholder="Select a sector..."
                options={selectData}
                form={form}
                rules={{ required: 'Please select an option' }}
              />
            </components.FormColumn>
          </components.FormRow>

          <button className='btn btn-pill-primary'>
            Continue
          </button>
        </form>
      </components.Body>
    </components.Container>
  );
}

export default SectorScreen2;
