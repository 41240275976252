import React from 'react';
import Enquiry1 from './Enquiry1';

export const Referrals = (): JSX.Element => {
  return <Enquiry1
    subtitle="Simply fill in the details below and we’ll be in touch"
    category="Referral Enquiry"
    formId="9c61bd53-1d4f-47d3-9514-5ec9bcfcf313"
    formTarget="hubspotReferralsForm"
    portalId="8639698"
    headerClass="referral-enquiry-header"
  />;
};

export default Referrals;
