import { format } from 'path';
import React from 'react';
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import { Controller } from 'react-hook-form';
import './ControlSelect.scss';

interface Props {
    name: string,
    placeholder: string,
    options: [string, string][],
    form: { register, control, errors },
    rules?: unknown,
    disabled?: boolean,
}

export const ControlSelect = (props: Props): JSX.Element => {
    const options = props.options.map(([value, label]) => {
        return {value, label};
    });

    const errors = props.form.errors[props.name];

    return (
        <div className="control-select">
            <Controller
                control={props.form.control}
                name={props.name}
                rules={props.rules}
                render={renderProps => {
                    return <Dropdown
                        value={renderProps.value}
                        options={options}
                        onChange={(option) => renderProps.onChange(option.value)}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        ref={...props.form.register("sector")}
                    />;
                }}
            />
            <div className="error">
                {errors && errors.message}
            </div>
        </div>
    );
};

export default ControlSelect;
