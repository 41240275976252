import ContactUs from "./screens/ContactUs";
import GetStarted from "./screens/GetStarted";
import WatchVideo from "./screens/WatchVideo";
import MobileMenu from "./screens/MobileMenu";
import * as ProductSector from "./screens/flows/productSector";
import * as ProductQuote from "./screens/flows/productQuote";
import * as Hubspot from "./screens/flows/hubspotForms";
import { Modal } from "./Enums";
import React from "react";

export const GetScreen = (modal: Modal | null) => {
  switch (modal) {
    case Modal.contactUs:
      return <ContactUs />;
    case Modal.getStarted:
      return <GetStarted />;
    case Modal.watchVideo:
      return <WatchVideo />;
    case Modal.enquiry1:
      return <Hubspot.enquiry.Enquiry1 />;
    case Modal.noviceEnquiry:
      return <Hubspot.enquiry.NoviceEnquiry />;
    case Modal.contactEnquiry:
      return <Hubspot.enquiry.ContactEnquiry />;
    case Modal.hqEnquiry:
      return <Hubspot.enquiry.HqEnquiry />;
    case Modal.partnerEnquiry:
      return <Hubspot.enquiry.PartnerEnquiry />;
    case Modal.cashPlus:
      return <Hubspot.enquiry.CashPlus />;
    case Modal.premierPlus:
      return <Hubspot.enquiry.PremierPlus />;
    case Modal.referrals:
      return <Hubspot.enquiry.Referrals />;
    case Modal.subscribe:
      return <Hubspot.enquiry.Subscribe />;
    case Modal.mobileMenu:
      return <MobileMenu />;
    case Modal.productQuote:
      return <ProductQuote.Screen1 />;
    case Modal.productQuoteScreen2:
      return <ProductQuote.Screen2 />;
    case Modal.productQuoteScreen3:
      return <ProductQuote.Screen3 />;
    case Modal.productSector:
      return <ProductSector.SectorScreen1 />;
    case Modal.productSectorScreen2:
      return <ProductSector.SectorScreen2 />;
    case null:
      return null;
    default:
      throw new Error("No modal found");
  }
};

export const MergeRefs = (...refs: any) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const DispatchReactLoad = () => {
  // @ts-ignore
  document.dispatchEvent(new Event("react:load"));
};

export const AddGTMEvent = (event: string) => {
  window["gtm"].addGTMEvent("modal", { modalAction: event });
};

export const disabledClass = (isValid: boolean) => {
  if (isValid === false) {
    return " disabled";
  } else {
    return "";
  }
};

export const getErrors = (rawErrors: { [key: string]: string[] }) => {
  const errors: string[] = [];

  // errors aren't returned consistently
  if (typeof(rawErrors) === "string") {
    errors.push(rawErrors);
  } else if (rawErrors) {
    for (const [key, value] of Object.entries(rawErrors)) {
      if (typeof(value) === "string") {
        errors.push(`${key}: ${value}`);
      } else if (Array.isArray(value)) {
        for (const errorItem of value) {
          errors.push(`${key}: ${errorItem}`);
        }
      } else {
        for (const [,errorItem] of Object.entries(value)) {
          errors.push(`${key}: ${errorItem}`);
        }
      }
    }
  }

  return errors;
};

export const ConvertFormBoolean = (value: boolean) => {
  if (value === true) {
    return "1";
  }
  return "0";
};
