import React from 'react';
import Enquiry1 from './Enquiry1';

export const PremierPlus = (): JSX.Element => {
  return <Enquiry1
    title="Speak to our team"
    subtitle="With Premier+ our experienced lawyers are on hand to provide ongoing support for large-scale projects and can impart expert insight and sound counsel when needed. Speak to our team to find out more about Premier+."
    category="Premier Plus"
    formId="e45022b7-444a-41d4-b8b5-c44b07930111"
    formTarget="hubspotPremierPlusForm"
    portalId="8639698"
    headerClass="contact-enquiry-header"
    formName="website_premier_plus_contact_form_submit"
  />;
};

export default PremierPlus;
