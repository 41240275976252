import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/Actions";
import * as components from "../../components";
import { Modal, ModalSize } from "../../../Enums";
import './ProductQuote.scss';
import { IAppState } from '../../../store/Reducers';
import { getCsrfToken } from '../../utils/common';

export const Screen2 = (): JSX.Element => {
  const dispatch = useDispatch();
  const productQuoteData = useSelector((state: IAppState) => state.productQuoteData);

  const handleClick = (item: any) => {
    const data = {
      ...productQuoteData,
      selectedProduct: {
        id: item.id,
        title: item.legal_category,
        lower_limit: item.lower_limit,
        lower_tooltip: item.lower_limit_tooltip,
        mid_limit: item.mid_limit,
        mid_tooltip: item.mid_limit_tooltip,
        upper_limit: item.upper_limit,
        upper_tooltip: item.upper_limit_tooltip,
        percentage: item.range_percentage,
      }
    };

    const registerFollowUp = async () => {
      fetch('/product_quotes.json', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': getCsrfToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          product_quote: { product_quote_range_id: item.id },
        })
      }).then(response => { console.log(response) })
        .catch(error => console.log(error))
    };
    registerFollowUp();

    dispatch(actions.changeProductQuoteData(data));
    dispatch(actions.nextModal(Modal.productQuoteScreen3));
  };

  return (
    <components.Container className="quote-modal-container" size={ModalSize.normal}>
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="pb-5 footer-as-bg">
        <components.Row>
          <components.Column>
            <components.Title>Get an Indicative Quote</components.Title>
            <p>Which legal category best fits your enquiry?</p>
          </components.Column>
        </components.Row>

        <hr className="my-1" />

        <div className="mt-3 mb-5 pb-2 quote-list">
          {productQuoteData.data.map(function (item: any) {
          return (
            <div className="quote-list-item" onClick={() => handleClick(item)}>
              {item.legal_category}<br />
              {item.legal_category_text}
            </div>
            )
          })}
        </div>
      </components.Body>
    </components.Container>
  );
};

export default Screen2;