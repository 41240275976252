import React from 'react';
import Enquiry1 from './Enquiry1';

export const NoviceEnquiry = (): JSX.Element => {
  return <Enquiry1
    subtitle=""
    category="Novice Enquiry"
    formId="16b63e9c-f297-4f4c-a3a9-3ae393c537ab"
    formTarget="hubspotNoviceForm"
    portalId="8639698"
    headerClass="novice-enquiry-header"
  />;
};

export default NoviceEnquiry;
