import * as actions from "../store/Actions";
import chevronIcon from 'images/mobile-menu/chevron.svg'
import {ScreenData} from "../Interfaces";
import React from 'react';

interface IMobileMenuItemProps {
    screenData: ScreenData,
    onClick: (title: string) => void,
}

function MobileMenuItem(props: IMobileMenuItemProps) {
    const onClick = (event: React.MouseEvent) => {
        if (props.screenData.children.length >= 1) {
            props.onClick(props.screenData.id)
            event.preventDefault();
        } else {
            actions.closeModal();
        }
    }

    return (
        <li className="menu-item">
            <a href={props.screenData.url} onClick={onClick} className={props.screenData.class}>
                {
                    props.screenData.icon && (
                        <div className="icon">
                            <img src={props.screenData.icon} alt="Menu item icon" />
                        </div>
                    )
                }
                <div className="title">
                    {props.screenData.title}
                </div>
                {
                    props.screenData.children.length >= 1 && (
                        <div className="chevron">
                            <img src={chevronIcon} alt="Next" />
                        </div>
                    )
                }
            </a>
        </li>
    );
}

export default MobileMenuItem;
