import React from 'react';
import Enquiry1 from './Enquiry1';

export const HqEnquiry = (): JSX.Element => {
  return <Enquiry1
    subtitle="Simply fill in the details below and we’ll be in touch to discuss how HQ can help your business"
    category="HQ Enquiry"
    formId="d537395c-90f4-4db7-8b7a-5a054836e662"
    formTarget="hubspotHqForm"
    portalId="8639698"
    headerClass="hq-enquiry-header"
  />;
};

export default HqEnquiry;
