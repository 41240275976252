import React from 'react';
import Enquiry1 from './Enquiry1';

export const ContactEnquiry = (): JSX.Element => {
  return <Enquiry1
    subtitle=""
    category="Contact Enquiry"
    formId="16b63e9c-f297-4f4c-a3a9-3ae393c537ab"
    formTarget="hubspotContactForm"
    portalId="8639698"
    headerClass="contact-enquiry-header"
    formName="website_contact_form_submit"
  />;
};

export default ContactEnquiry;
