import React from 'react';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/Actions";
import * as components from "../../components";
import { Modal, ModalSize } from "../../../Enums";
import { IAppState } from '../../../store/Reducers';

export const SectorScreen1 = (): JSX.Element => {
  const dispatch = useDispatch();
  const productSectorData = useSelector((state: IAppState) => state.productSectorData);
  const productInput = document.querySelector('input[name="sector_product_id"]') as HTMLInputElement;
  const productId = productInput ? productInput.value : '';
  const periodical = productInput.dataset.periodical;

  const loadData = async () => {
    axios.defaults.headers = {
      Accept: 'application/json',
      withCredentials: false,
      'Content-Type': 'application/json',
    };
    await axios.get(
        '/api/product_sectors',
        { params: { product_id: productId } }
      )
      .then(response => {
        const data = {
          ...productSectorData,
          data: response.data,
          periodical: periodical,
          productId: productId
        };
        dispatch(actions.changeProductSectorData(data));
        dispatch(actions.nextModal(Modal.productSectorScreen2));
      })
      .catch(error => console.log(error))
  };
  loadData();

  return (
    <components.Container className="sector-modal-container" size={ModalSize.normal}>
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="bottom-radius">
        <components.Row>
          <components.Column>
            <components.Title>Select your sector</components.Title>
          </components.Column>
        </components.Row>

        <hr className="my-1" />

        <p className="mt-3">
          So we can tailor your Foundations Plus plan to suit your needs please confirm which industry sector you work in.
        </p>

        <p className="mt-4 mb-0 middle-screen-with-bg">
          Loading...
        </p>
      </components.Body>
    </components.Container>
  );
}

export default SectorScreen1;