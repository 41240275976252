import React from 'react';
import * as components from "../../../components";
import { createForm } from "../Helpers";

interface Props {
  title?: string,
  subtitle?: string,
  category?: string,
  formId?: string,
  formTarget?: string,
  portalId?: string,
  headerClass?: string,
  formName?: string,
}

export const Enquiry1 = (props: Props): JSX.Element => {
  createForm(props.portalId, props.formId, props.formTarget, props.headerClass, props.formName);

  const title = props.title || 'Make an enquiry';
  const subTitle = props.subtitle || 'Thank you for your interest in LawBite. Please complete the form below with your enquiry and we will get back to you as soon as possible.';

  return (
    <components.Container className="hubspot-1-modal">
      <components.Header>
        <components.Close />
      </components.Header>

      <components.Body className="pb-5">
        <components.Title className={props.headerClass}>{title}</components.Title>
        <p className={"mb-3 " + props.headerClass}>{subTitle}</p>

        <components.Loader></components.Loader>
        <div>
          <div id={props.formTarget}></div>
        </div>
      </components.Body>
    </components.Container>
  );
}

export default Enquiry1;
