import * as actions from "../store/Actions";
import phoneImage from 'images/icons/phone.svg';
import chatImage from 'images/icons/live-chat.svg';
import enquiryImage from 'images/icons/enquiry.svg';
import {Modal, ModalSize} from "../Enums";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useDispatchReactLoad} from "../Hooks";
import './ContactUs.scss';
import * as components from "./components";
import { IAppState } from "../store/Reducers";
import { refCookieParams } from "../../../../src/cookie";

function ContactUs() {
    const dispatch = useDispatch();
    const portalUrl = useSelector((state: IAppState) => state.portalUrl);
    const portalParams = refCookieParams();

    useDispatchReactLoad();

    const callClicked = (event: React.MouseEvent) => {
        window.location.href = portalUrl + "/onboarding/enquiries" + portalParams;
        event.preventDefault();
    }

    const enquiryClicked = (event: React.MouseEvent) => {
        dispatch(actions.nextModal(Modal.contactEnquiry));
        event.preventDefault();
    }

    return (
        <components.Container className="contact-us-modal" size={ModalSize.large}>
            <components.Header>
                <components.Close />
            </components.Header>

            <components.Body className="pb-5 text-center">
                <components.Title className="mb-4" large={true}>Contact us</components.Title>

                <p className="line-height-large mb-4">
                    To speak to a member of our friendly customer service team, call us on <a href="tel: 020 7148 1066" className="traditional bold phone-number">020 7148 1066</a>.
                    <br />
                    Our office hours are 9AM - 5PM (GMT) Monday to Friday.
                    <br />
                    <br />
										If you’re looking for a quote for your legal work, click ‘Talk to a lawyer’ to book a free 15 minute consultation with one of our expert lawyers at a time that works for you.
                </p>

                <div className="d-flex justify-content-center mt-4">
                    <div className="col-md-4 mb-5 mb-md-0">
                        <div className="card h-100 shadow py-4 contact-option" onClick={callClicked}>
                            <img className="w-100 d-block mx-auto" height="30" src={phoneImage}
                                 alt="phone" />

                            <p className="h6 mb-0 mt-3">
                                Talk to a lawyer
                            </p>
                        </div>
                    </div>
                </div>
            </components.Body>
        </components.Container>
    );
}

export default ContactUs;
