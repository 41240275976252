import React from 'react';
import App from './App';
import {Provider} from 'react-redux';
import generateStore from "./store/Store";

const ReactModals = (props) => {
    return (
        <Provider store={generateStore()}>
            <React.StrictMode>
                <App {...props} />
            </React.StrictMode>
        </Provider>
    );
};

const wrapper = props => <ReactModals {...props} />;
export default wrapper; // needs to be wrapped in rails
