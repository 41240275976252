import React from 'react';
import Enquiry1 from './Enquiry1';

export const CashPlus = (): JSX.Element => {
  return <Enquiry1
    subtitle=""
    category="Cash Plus"
    formId="0af8d65d-fe6f-46fa-922e-8dea663992fc"
    formTarget="hubspotCashPlusForm"
    portalId="8639698"
    headerClass="novice-enquiry-header"
  />;
};

export default CashPlus;
