import React from 'react';

interface Props {
    className?: string,
    large?: boolean
}

const Title: React.FunctionComponent<Props> = props => {
    const component = props.large ? 'h2' : 'h3';

    return React.createElement(component, {className: props.className}, props.children);
}

export default Title;
