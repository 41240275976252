import React from 'react';
import Enquiry1 from './Enquiry1';

export const PartnerEnquiry = (): JSX.Element => {
  return <Enquiry1
    subtitle="Simply fill in the details below and we’ll be in touch to discuss this great offer"
    category="Partner Enquiry"
    formId="4dae02fa-694f-4943-8948-013b67735552"
    formTarget="hubspotPartnerForm"
    portalId="8639698"
    headerClass="partner-enquiry-header"
  />;
};

export default PartnerEnquiry;
