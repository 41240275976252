import * as actions from "../store/Actions";
import chevronIcon from "images/mobile-menu/chevron.svg";
import {CSSTransition} from 'react-transition-group'
import {IAppState} from "../store/Reducers";
import logoIcon from 'images/logos/logo.png'
import MobileMenuItem from "./MobileMenuItem";
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {MobileMenuType, Modal} from "../Enums";
import {ScreenData} from "../Interfaces";
import './MobileMenuContent.scss';

interface IMobileMenuContentProps {
    primary: boolean,
    screenId: string,
    isRoot?: boolean,
}

function MobileMenuContent(props: IMobileMenuContentProps) {
    useEffect(() => {
        const metaTag = document.querySelector('meta[name="login-url"]');
        if (metaTag) {
          const url = metaTag.getAttribute("content");
          setLoginUrl(url);
        }
      }, []);

    const GenerateSpareChild = (title, url) => {
        return {
            id: "",
            title: title,
            icon: undefined,
            url: url,
            class: undefined,
            children: []
        }
    }

    const dispatch = useDispatch();
    const screenDatas = useSelector((state: IAppState) => state.screenData) as { [key: string]: ScreenData; };
    const type = useSelector((state: IAppState) => state.mobileMenuType) as MobileMenuType;
    const screenData = screenDatas[props.screenId];
    const [loginUrl, setLoginUrl] = useState("");

    let children: ScreenData[];
    if (type === MobileMenuType.main) {
        children = screenData.children.map(x => screenDatas[x]);
    } else {
        children = [
            GenerateSpareChild("Dashboard", "/dashboard"),
            GenerateSpareChild("My Legal Advice", "/my-cases"),
            GenerateSpareChild("My Documents", "/my-documents"),
            GenerateSpareChild("My Account", "/my-account"),
            GenerateSpareChild("Logout", "/sessions/destroy"),
        ];
    }

    const [showScreen, setShowScreen] = useState(true);

    const onClick = (id: string) => {
        dispatch(actions.addScreen(id));
    }

    const onContactUsClick = (id: string) => {
        dispatch(actions.nextModal(Modal.contactUs));
    }

    const onLoginClick = () => {
        if (loginUrl) {
            window.location.href = loginUrl;
        }
    };    

    const onExited = () => {
        dispatch(actions.removeScreen());
    }

    let classes = "mobile-menu-content";
    if (props.primary) {
        classes += " primary";
    }

    let header: JSX.Element = <div className="header-inner"><img className="mobile-header-logo" src={logoIcon} alt="Logo" /></div>
    if (screenData.title) {
        header = (
            <div className="header-inner">
                <div className="back-chevron" onClick={() => setShowScreen(false)}>
                    <img src={chevronIcon} alt="Back" />
                </div>
                {
                    screenData.url && (
                        <a className="title" href={screenData.url} onClick={actions.closeModal}>
                            {screenData.title}
                        </a>
                    )
                }
                {
                    !screenData.url && (
                        <span className="title">{screenData.title}</span>
                    )
                }
            </div>
        );
    }

    return (
        <CSSTransition in={showScreen} timeout={700} appear={true} onExited={onExited} classNames="mobile-menu-content">
            <div className={classes}>
                <div className="header">
                    {
                        header
                    }
                </div>
                <div className="menu-items">
                    <ul>
                        {
                            children.map((x, i) => {
                                return (
                                    <MobileMenuItem
                                        screenData={x}
                                        onClick={onClick}
                                        key={i}
                                    />
                                );
                            })
                        }
                    </ul>
                </div>
                {
                    props.isRoot && (
                        <div className="menu-items menu-items-footer">
                            <ul>
                                <MobileMenuItem
                                    screenData={{
                                        id: "",
                                        title: "Contact Us",
                                        icon: undefined,
                                        url: undefined,
                                        class: 'contact-us',
                                        children: []
                                    }}
                                    onClick={onContactUsClick}
                                />
                                <li className="menu-item">
                                    <a className="login" href={loginUrl} onClick={onLoginClick}>
                                        <div className="title">Login</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )
                }
            </div>
        </CSSTransition>
    );
}

export default MobileMenuContent;
